/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useContext } from 'react';
import Slider from 'react-slick';
import styles from './ImageGallery.module.scss';
import agent from '../../api/agent';
import { AppContext } from '../Context/AppContext';
import { readFromCache, writeToCache } from '../../utils/cache';
import { IGalleryItem } from '../Context/types';

const groupByType = (data: IGalleryItem[]) =>
  data.reduce(
    (prev: any, item) => ({
      ...prev,
      [item.type as string]:
        item.multi?.toString() === 'true'
          ? [
              ...(prev[item.type] || []),
              {
                photo: item.photo,
                description: item.description,
              },
            ]
          : item.photo,
    }),
    {}
  );

const ImageGallery = () => {
  const settings = {
    className: 'slider variable-width',
    dots: false,
    arrows: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true,
    cssEase: 'linear',
  };

  const { gallery, setGallery } = useContext(AppContext);

  useEffect(() => {
    if (localStorage.getItem('gallery')) {
      const cachedData = readFromCache('gallery');
      setGallery(cachedData);
    }

    const fetchData = async () => {
      const fetchedImages = await agent.Gallery.list();
      if (Array.isArray(fetchedImages)) {
        writeToCache('gallery', groupByType(fetchedImages));
        setGallery(groupByType(fetchedImages));
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.imageGallery}>
      <div className={styles.container}>
        <Slider {...settings}>
          {gallery?.gallery?.map((item, index) => (
            <div key={index.toString()}>
              <img
                style={{ objectFit: 'cover' }}
                height={400}
                src={item.photo}
                alt={item.description}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ImageGallery;
