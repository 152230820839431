/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Link } from 'react-scroll';
import styles from './NavigationMobile.module.scss';

const NavigationMobile = () => {
  const [show, toggleShow] = useState<boolean>(false);

  return (
    <header>
      <div className={styles.container}>
        <div className={styles.logo}>Lift</div>
        <div
          className={`${styles.navIcon} ${show ? styles.open : ''}`}
          onClick={() => toggleShow(!show)}
        >
          <div className={`${styles.bar1} ${show && styles.change}`} />
          <div className={`${styles.bar2} ${show && styles.change}`} />
        </div>
      </div>
      <nav style={{ display: show ? 'block' : 'none' }}>
        <ul>
          <li>
            <Link
              to="menuContainer"
              spy
              smooth
              offset={-80}
              duration={500}
              onClick={() => toggleShow(!show)}
            >
              Menu
            </Link>
          </li>
          <li>
            <Link
              to="reservationsContainer"
              spy
              smooth
              offset={-80}
              duration={500}
              onClick={() => toggleShow(!show)}
            >
              Reservations
            </Link>
          </li>
          <li>
            <Link
              to="deliveryBoxMobileContainer"
              spy
              smooth
              offset={-80}
              duration={500}
              onClick={() => toggleShow(!show)}
            >
              Delivery Box
            </Link>
          </li>
          <li>
            <Link
              to="contactContainer"
              spy
              smooth
              offset={-80}
              duration={500}
              onClick={() => toggleShow(!show)}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default NavigationMobile;
