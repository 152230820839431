import React from 'react';
import Helmet from 'react-helmet';

export default () => (
  <>
    <Helmet>
      <title>Lift Restaurant - Orderoo</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
    </Helmet>
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'white',
      }}
    >
      <iframe
        title="orderoo-iframe"
        style={{ width: '100%', height: '100%', border: 0 }}
        src="https://orderoo.ai/liftrestaurant"
      />
    </div>
  </>
);
