import React from 'react';
import agent from '../../api/agent';

const staticMenu = {
  business_lunch: {
    name: 'business_lunch.pdf',
    id: 'eb3abdcb-8f19-41ab-bd58-bf81f696b497',
  },
  caffeteria: {
    name: 'caffeteria.pdf',
    id: 'cl155jqyp00128k4e2saigexk',
  },
  drinks: {
    name: 'drinks.pdf',
    id: '711cc4bb-9823-4d7b-b748-86907cc6a524',
  },
  lunch_dinner: {
    name: 'lunch_dinner.pdf',
    id: 'e5104a81-322f-4c80-a682-589c1098b981',
  },
  wine: {
    name: 'wine.pdf',
    id: '748dbbfb-1d12-41ec-a374-2d7afaf4e297',
  },
};

const getSingleMenu = (id: any) => agent.Menu.listOne(id);

const Presentational = () => {
  const currentMenu = window.location.pathname.split('/').pop();

  Object.values(staticMenu).forEach((menu) => {
    if (currentMenu === menu.name) {
      getSingleMenu(menu.id)
        .then((fetchedMenu: any) => {
          window.location.href = fetchedMenu.Item.urlEn;
        })
        .catch(() => {});
    }
  });

  return (<div />)
}


export default Presentational;