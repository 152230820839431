import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { animateScroll as scroll } from 'react-scroll';
import DeliveryBox from './components/DeliveryBox/DeliveryBox';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import ImageGallery from './components/ImageGallery/ImageGallery';
import Menu from './components/Menu/Menu';
import Reservation from './components/Reservation/Reservation';
import { AppProvider } from './components/Context/AppContext';

const App = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(window.pageYOffset > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div style={{ overflowX: 'hidden' }}>
        <AppProvider>
          <Home />
          <Menu />
          <Reservation />
          <DeliveryBox />
          <ImageGallery />
          <Footer />
        </AppProvider>
      </div>
      {isVisible && (
        <div className="backToTop">
          <div
            role="button"
            className="triangle"
            onClick={() => scroll.scrollToTop()}
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
};

export default App;
