import { Link } from 'react-scroll';
import { useContext } from 'react';
import styles from './HomeMobile.module.scss';
import { AppContext } from '../../Context/AppContext';

import { imageFallback } from '../../../constants/fallbacks';

const HomeMobile = () => {
  const { gallery } = useContext(AppContext);

  return (
    <div className={styles.home}>
      <div className={styles.headerEmptyContainer} />
      <div className={styles.lift}>
        <div className={styles.triangleContainer}>
          <div className={styles.triangleUp} />
          <div className={styles.triangleDown} />
        </div>
        <p className={styles.elevateSenses}>Elevate your senses</p>
        <div className={styles.flavour}>
          <p>Take flavour to the next level</p>
        </div>
      </div>
      <div className={styles.nextLevel}>
        <img src={gallery?.homeImage || imageFallback.homeImage} alt="Dining Table" />
      </div>
      <div className={styles.meat}>
        <img src={gallery?.eventImage || imageFallback.eventImage} alt="Meat" />
      </div>
      <div className={styles.degustationMenu}>
        <div className={styles.upcomingEvent}>Upcoming event</div>
        <div className={styles.title}>Degustation Menu</div>
        <Link to="reservationsContainer" spy smooth offset={-80} duration={500}>
          Book Now
        </Link>
      </div>
    </div>
  );
};

export default HomeMobile;
