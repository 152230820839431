import React, { Dispatch, SetStateAction, useState } from 'react';
import { IMenu, IGallery } from './types';

interface IContextProps {
  menu?: IMenu[];
  setMenu: Dispatch<SetStateAction<IMenu[] | undefined>>;
  gallery?: IGallery;
  setGallery: Dispatch<SetStateAction<IGallery | undefined>>;
  hasError: boolean;
  setHasError: Dispatch<SetStateAction<boolean>>;
}

export const AppContext = React.createContext({} as IContextProps);

export const AppProvider = ({ children }: any) => {
  const [menu, setMenu] = useState<IMenu[]>();
  const [gallery, setGallery] = useState<IGallery>();
  const [hasError, setHasError] = useState<boolean>(true);

  return (
    <AppContext.Provider
      value={{
        menu,
        setMenu,
        gallery,
        setGallery,
        hasError,
        setHasError,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
