import React, { useContext } from 'react';
import { Link } from 'react-scroll';
import styles from './Home.module.scss';
import '../../styles.scss';
import singleTriangle from '../../assets/images/single_triangle.png';
import HomeMobile from './HomeMobile/HomeMobile';
import NavigationMobile from './NavigationMobile/NavigationMobile';
import { AppContext } from '../Context/AppContext';

import { imageFallback } from '../../constants/fallbacks';

const Home = () => {
  const { gallery } = useContext(AppContext);

  return (
    <>
      <div className={styles.home}>
        <div className={styles.lift}>
          <div className={styles.logo}>Lift</div>
          <div className={styles.navigationMenu}>
            <ul>
              <li>
                <Link to="menuContainer" spy smooth duration={500}>
                  Menu
                  <img src={singleTriangle} alt="Single Triangle" />
                </Link>
              </li>
              <li>
                <Link to="reservationsContainer" spy smooth duration={600}>
                  Reservations
                  <img src={singleTriangle} alt="Single Triangle" />
                </Link>
              </li>
              <li>
                <Link to="deliveryBoxContainer" spy smooth duration={700}>
                  Delivery&nbsp;Box
                  <img src={singleTriangle} alt="Single Triangle" />
                </Link>
              </li>
              <li>
                <Link to="contactContainer" spy smooth duration={800}>
                  Contact
                  <img src={singleTriangle} alt="Single Triangle" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.nextLevel}>
          <div className={`${styles.triangleContainer} animate__animated animate__slideInUp`}>
            <div className={styles.triangleUp} />
            <div className={styles.triangleDown} />
          </div>
          <div className={styles.elevateSenses}>
            <p>Elevate your senses</p>
          </div>
          <div className={styles.flavour}>
            <p>Take flavour to the next level</p>
          </div>
          <div className={styles.diningTable}>
            <img src={gallery?.homeImage || imageFallback.homeImage} alt="Dining Table" />
          </div>
        </div>
        <div className={styles.degustationMenu}>
          <img src={gallery?.eventImage || imageFallback.eventImage} alt="Degustation Menu" />
          <div className={styles.upcomingEvent}>Upcoming event</div>
          <div className={styles.title}>Degustation Menu</div>
          <div className={styles.description}>
            The steak aisle. What cuts of steak are the best? How can you tell a good cut from a bad
            one? There’s nothing better than a juicy, flavorful grilled steak. But, the best
            grilled, fried, or baked steak starts with choosing the best cut.
          </div>
          <Link to="reservationsContainer" spy smooth duration={700}>
            Book Now
          </Link>
        </div>

        <div className={styles.separator} />
      </div>

      <div className={styles.mobile}>
        <NavigationMobile />
        <HomeMobile />
      </div>
    </>
  );
};

export default Home;
