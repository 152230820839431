import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import IReservationsFormData from './models/reservation';
import { IGallery, IMenu } from './types';

const { REACT_APP_URL } = process.env;

axios.defaults.baseURL = REACT_APP_URL;

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const configuration = config;

    // const token = window.localStorage.getItem('token');
    // if (token) configuration.headers.Authorization = `Bearer ${token}`;
    return configuration;
  },
  (error: AxiosError) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    // const status = error.response?.status;
    throw error.response;
  }
);

const responseData = (response: AxiosResponse) => response?.data;

const request = {
  get: (url: string, params: any = undefined) => axios.get(url, { params }).then(responseData),
  post: (url: string, body: {}) => axios.post(url, body).then(responseData),
  put: (url: string, body: {}) => axios.put(url, body).then(responseData),
  patch: (url: string, body: {}) => axios.patch(url, body).then(responseData),
  delete: (url: string) => axios.delete(url).then(responseData),
};

const Reservations = {
  list: (): Promise<any> => request.get('/reservations/list'),
  add: (reservation: IReservationsFormData): Promise<any> =>
    request.post('/reservations/create', reservation),
};

const Gallery = {
  list: (): Promise<IGallery[]> => request.get('/gallery/list/lift'),
};

const Menu = {
  list: (): Promise<IMenu[]> => request.get('menu/list/lift'),
  listOne: (id: any): Promise<IMenu> => request.get(`menu/listone/${id}`)
};

export default {
  Reservations,
  Gallery,
  Menu,
};
