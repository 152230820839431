import { useContext } from 'react';
import { Element } from 'react-scroll';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from '../Context/AppContext';
import { imageFallback } from '../../constants/fallbacks';
import styles from './Reservation.module.scss';

const useStyles = makeStyles(() => ({
  submitButton: {
    color: '#c1c4b7',
    backgroundColor: '#995a3f',
    fontFamily: 'TelecomLight',
    fontSize: '1.875rem',
    marginTop: '2rem',
    textTransform: 'uppercase',
    height: '4rem',
    lineHeight: '2.5rem',
    boxShadow: 'none',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#995a3f',
    },
    '@media (max-width:768px)': {
      fontSize: '0.875rem',
      marginTop: '1rem',
      height: '3rem',
      lineHeight: '1.3rem',
    },
  },
  input: {
    '& label.Mui-focused': {
      color: '#151617',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#151617',
    },
  },
  inputRoot: {
    fontSize: '1.5rem',
    color: '#151617',
    fontFamily: 'AeroportLight',
    '@media (max-width:768px)': {
      fontSize: '1rem',
    },
  },
  labelRoot: {
    fontSize: '1.5rem',
    fontFamily: 'AeroportLight',
    '@media (max-width:768px)': {
      fontSize: '1rem',
    },
  },
  error: {
    color: 'red',
  },
}));

const Reservation = () => {
  const classes = useStyles();
  const { gallery } = useContext(AppContext);

  return (
    <Element name="reservationsContainer" className={styles.reservation}>
      <div className={styles.number}>02</div>
      <div className={styles.circle}>
        <div className={styles.draw} />
      </div>
      <div className={styles.form}>
        <div className={styles.title}>Reservation</div>
        <div className={styles.contact}>Come to meet us and enjoy a great food experience.</div>
        <Button
          className={classes.submitButton}
          type="submit"
          fullWidth
          disableRipple
          onClick={() => {
            window.location.href = 'https://www.sevenrooms.com/reservations/liftsteakrooftopbar';
          }}
        >
          Book a table
        </Button>
      </div>
      <div className={styles.dining}>
        <img src={gallery?.reservationImage || imageFallback.reservationImage} alt="Dining Table" />
      </div>
    </Element>
  );
};

export default Reservation;
