/* eslint-disable react/jsx-indent */
import { useContext, useEffect } from 'react';
import { Element } from 'react-scroll';
import styles from './Menu.module.scss';
import { AppContext } from '../Context/AppContext';
import { writeToCache, readFromCache } from '../../utils/cache';
import agent from '../../api/agent';
import '../../styles.scss';
// import Collapsible from '../Collapsible/Collapsible';

import { imageFallback, menuFallback } from '../../constants/fallbacks';

const Menu = () => {
  const { menu, setMenu, gallery } = useContext(AppContext);

  useEffect(() => {
    if (localStorage.getItem('menu')) {
      const cachedData = readFromCache('menu');
      setMenu(cachedData);
    }

    const fetchData = async () => {
      try {
        const fetchedMenu = await agent.Menu.list();
        if (Array.isArray(fetchedMenu)) {
          writeToCache('menu', fetchedMenu);
          setMenu(fetchedMenu);
        }
      } catch {
        setMenu(undefined);
      }
    };

    fetchData();
  }, []);

  return (
    <Element name="menuContainer" className={styles.menu}>
      <div className={styles.header}>
        <div className={styles.headerIcon}>
          <div className={styles.triangle} />
        </div>
        <div className={styles.headerDescription}>
          Located at the one of the most iconic commercial buildings of the city of Tirana, Aba
          Business Center, LIFT is about represanting a concept of a classic and sophisticated
          steakhouse combined with style and vibes of a nightlife experience.
          {/* <br /> <br /> Our
        mission is to create the finest experience for our guests, by giving the highest quality of
        prime steaks and seafood, with exceptional service in a cosmopolitan and entertaining
        atmosphere. */}
        </div>
        <div />
      </div>

      <div className={styles.menuContent}>
        <div className={styles.menuNumber}>01</div>
        <div className={styles.menuDescription}>
          <p>Steak &#38; Rooftop bar</p>
          <img src={gallery?.menuImage || imageFallback.menuImage} alt="Meat" />
        </div>
        <div className={styles.menuSide}>
          <p>
            Feel like eating in? LIFT delivery service is slated to begin operations soon. You will
            be able to order our exquisite menu via Baboon!
          </p>
          <div className={styles.sideImage}>
            <img src={gallery?.eventImage || imageFallback.eventImage} alt="Meat" />
            <div className={styles.hrContainer}>
              <hr />
            </div>
          </div>
        </div>
        <div className={styles.menuDetails}>
          {Array.isArray(menu) && menu.length
            ? menu.map((item, i) => (
                // <Collapsible key={i.toString()} content={el} />
                <div key={i.toString()} className={styles.menuItem}>
                  <div className={styles.title}>{item.titleEn}</div>
                  <a className={styles.download} href={item.urlEn} target="_blank" rel="noreferrer">
                    Click to download
                  </a>
                </div>
              ))
            : menuFallback.map((item, i) => (
                // <Collapsible key={i.toString()} content={el} />
                <div key={i.toString()} className={styles.menuItem}>
                  <div className={styles.title}>{item.title}</div>
                  <a className={styles.download} href={item.link} target="_blank" rel="noreferrer">
                    Click to download
                  </a>
                </div>
              ))}
        </div>
        <div className={styles.menuOverview}>Menu &#38; Delivery</div>
      </div>
    </Element>
  );
};

export default Menu;
