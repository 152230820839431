import homeImage from '../assets/images/home_table.png';
import deliveryImage from '../assets/images/wine.png';
import reservationImage from '../assets/images/home_table_cut.png';
import menuImage from '../assets/images/meat_menu.jpg';
import eventImage from '../assets/images/home_meat.png';

export const imageFallback = {
  homeImage,
  eventImage,
  menuImage,
  reservationImage,
  deliveryImage,
};

export const menuFallback = [
  {
    title: 'Wine',
    link: 'https://rhg-services-menus-production.s3.eu-central-1.amazonaws.com/0c82087d-a106-4280-a9f6-5e292b44c827_d79833889775e4719cdb3a099f612ee8_wine_compressed.pdf',
  },
  {
    title: 'Lunch & Dinner',
    link: 'https://rhg-services-menus-production.s3.eu-central-1.amazonaws.com/e5104a81-322f-4c80-a682-589c1098b981_7f7e6f67fc5f5cf1631edfe4901c35ed_lunch_dinner_compressed.pdf',
  },
  {
    title: 'Business Lunch',
    link: 'https://rhg-services-menus-production.s3.eu-central-1.amazonaws.com/eb3abdcb-8f19-41ab-bd58-bf81f696b497_5d7f5dd8c7beb0793eacffc6c8177f1a_business_lunch_compressed.pdf',
  },
  {
    title: 'Caffeteria',
    link: 'https://rhg-services-menus-production.s3.eu-central-1.amazonaws.com/cl155jqyp00128k4e2saigexk_a9183fca1b3a3be2c6af0c357c39e819_caffeteria_compressed.pdf',
  },
  {
    title: 'Drinks',
    link: 'https://rhg-services-menus-production.s3.eu-central-1.amazonaws.com/711cc4bb-9823-4d7b-b748-86907cc6a524_d1c2cf23d6bc7b4aa64358d0305d2c6d_drinks.pdf',
  },
];
