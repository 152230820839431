import React, { useContext } from 'react';
import styles from './DeliveryBoxMobile.module.scss';
import { AppContext } from '../../Context/AppContext';

import { imageFallback } from '../../../constants/fallbacks';

const DeliveryBoxMobile = () => {
  const { gallery } = useContext(AppContext);

  return (
    <div className={styles.deliveryBox}>
      <div className={styles.header}>
        <img src={gallery?.deliveryImage || imageFallback.deliveryImage} alt="Wine" />
      </div>
      <div className={styles.number}>03</div>
      <div className={styles.box}>
        <div className={styles.title}>Online delivery box</div>
        <div className={styles.description}>
          Feel like eating in? LIFT delivery service is slated to begin operations soon. You will be
          able to order our exquisite menu via Baboon!
        </div>
        {/* <div className={styles.orderNow}>Order now online</div> */}
      </div>

      <div className={styles.customShape}>
        <div className={styles.triangleContainer}>
          <div className={styles.triangle} />
        </div>
      </div>
    </div>
  );
};

export default DeliveryBoxMobile;
