import React from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import OrderooIframe from './components/Orderoo/OrderooIframe';
import RedirectMenu from './components/RedirectMenu/Presentational'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/*.pdf" component={RedirectMenu} />
        <Route path="/order" component={OrderooIframe} />
        <Route render={() => <App />} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
