import React, { useContext } from 'react';
import { Element } from 'react-scroll';
import styles from './DeliveryBox.module.scss';
import DeliveryBoxMobile from './DeliveryBoxMobile/DeliveryBoxMobile';
import { AppContext } from '../Context/AppContext';

import { imageFallback } from '../../constants/fallbacks';

const DeliveryBox = () => {
  const { gallery } = useContext(AppContext);

  return (
    <>
      <Element name="deliveryBoxContainer" className={styles.deliveryBox}>
        <div className={styles.wine}>
          <div className={styles.number}>03</div>
          <div className={styles.wineContainer}>
            <img src={gallery?.deliveryImage || imageFallback.deliveryImage} alt="Wine" />
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.title}>Online delivery box</div>
          <div className={styles.description}>
            Feel like eating in? LIFT delivery service is slated to begin operations soon. You will
            be able to order our exquisite menu via Baboon!
          </div>
          {/* <div className={styles.orderNow}>Order now online</div> */}
        </div>
        <div className={styles.customShape}>
          <div className={styles.triangleContainer}>
            <div className={styles.triangle} />
          </div>
        </div>
      </Element>

      <Element className={styles.mobile} name="deliveryBoxMobileContainer">
        <DeliveryBoxMobile />
      </Element>
    </>
  );
};

export default DeliveryBox;
