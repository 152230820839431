import { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import styles from './Footer.module.scss';

const Footer = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile: boolean = width <= 768;

  return (
    <div className={styles.footer}>
      <div className={styles.logo}>Lift</div>
      <div className={styles.menu}>
        <ul>
          <li>
            <Link to="menuContainer" spy smooth offset={isMobile ? -80 : 0} duration={800}>
              Menu
            </Link>
          </li>
          <li>
            <Link to="reservationsContainer" spy smooth offset={isMobile ? -80 : 0} duration={700}>
              Reservations
            </Link>
          </li>
          <li>
            <Link to="deliveryBoxContainer" spy smooth offset={isMobile ? -80 : 0} duration={600}>
              Delivery
            </Link>
          </li>
          <li>
            <Link to="contactContainer" spy smooth offset={isMobile ? -80 : 0} duration={500}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
